import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { UnsubscribeComponent } from './components/pages/unsubscribe/unsubscribe.component';
import { AppleNowComponent } from './components/pages/apple-now/apple-now.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogStyleOneComponent } from './components/pages/blog-style-one/blog-style-one.component';
import { BlogStyleTwoComponent } from './components/pages/blog-style-two/blog-style-two.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { ItconsultingServicesComponent } from './components/pages/itconsulting-services/itconsulting-services.component';
import { ItstaffingServicesComponent } from './components/pages/itstaffing-services/itstaffing-services.component';
import { IttrainingServicesComponent } from './components/pages/ittraining-services/ittraining-services.component';
import { ClientsComponent } from './components/pages/jobseekers/clients/clients.component';
import { JobdetailsComponent } from './components/pages/jobseekers/jobdetails/jobdetails.component';
import { JobseekersComponent } from './components/pages/jobseekers/jobseekers.component';
import { OpenpositionsComponent } from './components/pages/openpositions/openpositions.component';
import { PrepFeedComponent } from './components/pages/prepfeed/prepfeed.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ProjectsDetailsComponent } from './components/pages/projects-details/projects-details.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ServicesStyleOneComponent } from './components/pages/services-style-one/services-style-one.component';
import { ServicesStyleTwoComponent } from './components/pages/services-style-two/services-style-two.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { TrainingComponent } from './components/pages/training/training.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'righttalents', component: HomeOneComponent},
    {path: 'unsubscribe', component: UnsubscribeComponent},
    {path: 'subscribe', component: UnsubscribeComponent},
    {path: 'home', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'team', component: TeamComponent},
    {path: 'apply-now', component: AppleNowComponent},
    {path: 'projects', component: ProjectsComponent},
    {path: 'projects-details', component: ProjectsDetailsComponent},
    {path: 'case-study', component: CaseStudyComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'testimonials', component: TestimonialsComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'sign-in', component: SignInComponent},
    {path: 'sign-up', component: SignUpComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'services-1', component: HomeOneComponent},
    {path: 'services-2', component: ServicesStyleTwoComponent},
    {path: 'services-details', component: ServicesDetailsComponent},
    {path: 'itconsulting-services', component: ItconsultingServicesComponent},
    {path: 'itstaffing-services', component: ItstaffingServicesComponent},
    {path: 'ittraining-services', component: IttrainingServicesComponent},
    {path: 'employers', component: EmployersComponent },
    {path: 'jobseekers', component: JobseekersComponent },
    {path: 'openpositions/jobdetails/:jobid', component: JobdetailsComponent }, 
    {path: 'clients', component: ClientsComponent },
    {path: 'openpositions', component: OpenpositionsComponent },
    {path: 'prepfeed', component: PrepFeedComponent },
    {path: 'training', component: TrainingComponent },
    {path: 'blog-1', component: BlogStyleOneComponent},
    {path: 'blog-2', component: BlogStyleTwoComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'contact', component: ContactComponent},
    {path: '**', component: ErrorComponent} // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: false, initialNavigation: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }