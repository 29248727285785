<!-- Inner Banner -->
<div class="inner-banner openpositions-bg">
    <div class="container">
        <div class="inner-title text-center adjust-banner">
            <h3 class="inner-headline headerText">Interview Preparation Feed</h3>
            <p class="inner-headline1 headerText">*Career Guidance, Interview Preparation, Techincal Updates, Learning materials, Key concepts*</p>
            <p class="inner-headline2 headerText">Reference links are shared at the bottom of the card </p>
            <!--ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Open Positions</li>
            </ul-->
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Services Details -->
<div class="services-details pt-45 pl-20">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-details-content">
                    <div class="service-advantage">
                        <div class="row">
                            <!--div-->
                                <!--h3>Open Positions</h3-->
                                <!--p>Click on job title to know more about the job and apply for it. Or <a class="applyBtn" (click)="openPopup('')"> drop in your resume</a>, we will get back to you for a suitable openings.</p>
                                <p>If your are a bench sales recruiter, please <a class="applyBtn" (click)="openPopup('BENCH SALES RECRUITER')"> register here </a> to get the job post notifications.</p>
                                <p>Whether you want to work for us as a US IT Recruiter, please <a class="applyBtn" (click)="openPopup('US IT Recruiter')"> apply here </a> </p>
                            </div-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="max-width: 1400px !important;">
        <div class="row">
            <!--h3 class="title">Job Posts</h3-->
            <ul class="row" style="list-style-type: none; padding: 0; margin-right: 0">
                <li  class="col-lg-4 col-md-4 col-sm-12" style="padding: 5px; overflow: hidden;">
                    <div class="card mb-2 jobpost-card">
                        <div class="d-flex justify-content-between " >
                            <a style=" display: inline" >
                                <div class="d-flex flex-row align-items-center">
                                    <div class="ms-5 c-details jobheader" style="display: flex;">
                                        <div class="col-lg-10 col-md-10 col-sm-10">

                                            <h4 class="jobtitle" style="padding-top: 10px; text-align: center;">
                                                Pratice using AI Interviewer
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="p-3">    
                            <div class="mt-3 ">
                                To help you pratice your skills with AI to face the interview with confidence use AI interview prepration site.
                                <ul>
                                    <li>You just need to put the skills you want to pratice to face the interview. </li>
                                    <li>AI-Powered mock interviewers will ask you the best interview questions, and give you a robust assessment on your answers.</li>
                                    <li>Practice 15 mins a day & land your dream job.</li>
                                </ul>
                            </div>
                            <div class="mt-3">
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div class="mt-3 row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <span class="text1">Link: </span>
                                        <a href="https://www.micro1.ai/interview-prep">https://www.micro1.ai/interview-prep</a>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <span class="text1">Tags: </span>
                                        #general, #interviewprep
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </li>
                <li  class="col-lg-4 col-md-4 col-sm-12" style="padding: 5px; overflow: hidden;">
                    <div class="card mb-2 jobpost-card">
                        <div class="d-flex justify-content-between " >
                            <a style=" display: inline" >
                                <div class="d-flex flex-row align-items-center">
                                    <div class="ms-5 c-details jobheader" style="display: flex;">
                                        <div class="col-lg-10 col-md-10 col-sm-10">

                                            <h4 class="jobtitle" style="padding-top: 10px; text-align: center;">
                                                Resume Creation Tips for US Jobs
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="p-3">    
                            <div class="mt-3 ">
                                How to Write an American Resume? 
                                <ul>
                                    <li>Stick to one to two pages in length. If you create a two-page resume, ensure that the entire second page is filled.</li>
                                    <li>Write your resume on a U.S. letter document (8.5” x 11”). </li>
                                    <li>Include clear section headings and ample white space so that your resume is easy to scan.</li>
                                </ul>
                            </div>
                            <div class="mt-3">
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div class="mt-3 row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <span class="text1">Link: </span>
                                        <a href="https://www.myperfectresume.com/career-center/resumes/basics/us">https://www.myperfectresume.com/career-center/resumes/basics/us</a>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <span class="text1">Tags: </span>
                                        #general, #interviewprep
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </li>
                <li  class="col-lg-4 col-md-4 col-sm-12" style="padding: 5px; overflow: hidden;">
                    <div class="card mb-2 jobpost-card">
                        <div class="d-flex justify-content-between " >
                            <a style=" display: inline" >
                                <div class="d-flex flex-row align-items-center">
                                    <div class="ms-5 c-details jobheader" style="display: flex;">
                                        <div class="col-lg-10 col-md-10 col-sm-10">

                                            <h4 class="jobtitle" style="padding-top: 10px; text-align: center;">
                                                Need a mentorship
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="p-3">    
                            <div class="mt-3 ">
                                Land your dream job, role, and company faster than ever with 1:1 long term mentorship.
                                <ul>
                                    <li>No Payment Required </li>
                                    <li>Verified Mentors Only</li>
                                    <li>Reschedule Anytime</li>
                                </ul>
                            </div>
                            <div class="mt-3">
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div class="mt-3 row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <span class="text1">Link: </span>
                                        <a href="https://www.preplaced.in/">https://www.preplaced.in/</a>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <span class="text1">Tags: </span>
                                        #general, #interviewprep
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </li>
                <li  class="col-lg-4 col-md-4 col-sm-12" style="padding: 5px; overflow: hidden;">
                    <div class="card mb-2 jobpost-card">
                        <div class="d-flex justify-content-between " >
                            <a style=" display: inline" >
                                <div class="d-flex flex-row align-items-center">
                                    <div class="ms-5 c-details jobheader" style="display: flex;">
                                        <div class="col-lg-10 col-md-10 col-sm-10">

                                            <h4 class="jobtitle" style="padding-top: 10px; text-align: center;">
                                                Interview Question for different jobs
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="p-3">    
                            <div class="mt-3 ">
                                Looking for more common interview questions and answers examples? Please check the below site with different questions tags.
                            </div>
                            <div class="mt-3">
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div class="mt-3 row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <span class="text1">Link: </span>
                                        <a href="https://www.themuse.com/advice/interview-questions-and-answers">https://www.themuse.com/advice/interview-questions-and-answers</a>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <span class="text1">Tags: </span>
                                        #general, #interviewprep
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </li>
                <li  class="col-lg-4 col-md-4 col-sm-12" style="padding: 5px; overflow: hidden;">
                    <div class="card mb-2 jobpost-card">
                        <div class="d-flex justify-content-between " >
                            <a style=" display: inline" >
                                <div class="d-flex flex-row align-items-center">
                                    <div class="ms-5 c-details jobheader" style="display: flex;">
                                        <div class="col-lg-10 col-md-10 col-sm-10">

                                            <h4 class="jobtitle" style="padding-top: 10px; text-align: center;">
                                                Selective Network Engineer Questions 
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="p-3">    
                            <div class="mt-3 ">
                                here are 30 interview questions and answer examples for Network Engineer jobs which was updated by William Swansen.
                            </div>
                            <div class="mt-3">
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div class="mt-3 row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <span class="text1">Link: </span>
                                        <a href="https://www.mockquestions.com/position/Network+Engineer/">https://www.mockquestions.com/position/Network+Engineer/</a>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <span class="text1">Tags: </span>
                                        #networkengineer, #interviewprep
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </li>
                <li  class="col-lg-4 col-md-4 col-sm-12" style="padding: 5px; overflow: hidden;">
                    <div class="card mb-2 jobpost-card">
                        <div class="d-flex justify-content-between " >
                            <a style=" display: inline" >
                                <div class="d-flex flex-row align-items-center">
                                    <div class="ms-5 c-details jobheader" style="display: flex;">
                                        <div class="col-lg-10 col-md-10 col-sm-10">

                                            <h4 class="jobtitle" style="padding-top: 10px; text-align: center;">
                                                Self assessment tool for network engineer
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="p-3">    
                            <div class="mt-3 ">
                                Self assessment tool to do a pre-check of taking the MCQ questions for the job roles. It also provide automated interviews.
                            </div>
                            <div class="mt-3">
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div class="mt-3 row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <span class="text1">Link: </span>
                                        <a href="https://xobin.com/job-test-portfolio/network-engineer-test/">https://xobin.com/job-test-portfolio/network-engineer-test/</a>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <span class="text1">Tags: </span>
                                        #networkengineer, #interviewprep
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </li>
            </ul>
        </div>
    </div>

</div>
<!-- Services Details End -->

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 *ngIf="!jobpostTitle" class="modal-title">Subscribe for the feed</h4>
                <a class="modelClose" (click)="closePopup()">X</a>
            </div>
            <div class="modal-body">
                <div class="contact-form" style="padding: 5px !important;">
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row ">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="firstname" id="firstname" type="text" placeholder="First Name" class="form-control ">
                                    <div *ngIf="f.firstname.touched && f.firstname.invalid" class="invalid-feedback">
                                        <div *ngIf="f.firstname.errors.required">First Name is required.</div>
                                        <div *ngIf="f.firstname.errors.minlength">First Name should be 3 character.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="lastname" id="lastname" type="text" placeholder="Last Name" class="form-control">
                                    <div *ngIf="f.lastname.touched && f.lastname.invalid" class="invalid-feedback">
                                        <div *ngIf="f.lastname.errors.required">Last Name is required.</div>
                                        <div *ngIf="f.lastname.errors.minlength">Last Name should be 3 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="email" id="email" type="email" placeholder="Email" class="form-control">
                                    <div *ngIf="f.email.touched && f.email.invalid" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required.</div>
                                        <div *ngIf="f.email.errors.email">Please, enter valid email address.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="phone" id="phone" type="tel" pattern="[0-9]{10}" placeholder="Phone" class="form-control">
                                    <div *ngIf="f.phone.touched && f.phone.invalid" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">phone is required.</div>
                                        <div *ngIf="f.phone.errors.pattern">Should be 10 digit mobile number</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>

        </div>
    </div>
</div>