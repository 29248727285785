<!-- Inner Banner -->
<div class="inner-banner openpositions-bg">
    <div class="container">
        <div class="inner-title text-center adjust-banner">
            <h3 class="inner-headline headerText">Open Positions</h3>
            <p class="inner-headline1 headerText">Lot of opportunities, apply the ones which suits you best!
            </p>
            <p class="inner-headline2 headerText">*Payscale may vary depends on experience!</p>
            <!--ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Open Positions</li>
            </ul-->
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Services Details -->
<div class="services-details pt-45 pl-20">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="services-details-content">
                    <div class="service-advantage">
                        <div class="row">
                            <div>
                                <!--h3>Open Positions</h3-->
                                <p>Click on job title to know more about the job and apply for it. Or <a class="applyBtn" (click)="openPopup('')"> drop in your resume</a>, we will get back to you for a suitable openings. For job Prepration, we <a href="/prepfeed">handpicked articles</a> here to help you for your prepration.</p>
                                <p>If your are a bench sales recruiter, please <a class="applyBtn" (click)="openPopup('BENCH SALES RECRUITER')"> register here </a> to get the job post notifications.</p>
                                <p>Whether you want to work for us as a US IT Recruiter, please <a class="applyBtn" (click)="openPopup('US IT Recruiter')"> apply here </a> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="max-width: 1400px !important;">
        <div class="row">
            <!--h3 class="title">Job Posts</h3-->
            <ul class="row" style="list-style-type: none; padding: 0; margin-right: 0">
                <li *ngFor="let jobdetails of jobposts" class="col-lg-4 col-md-4 col-sm-12" style="padding: 5px; overflow: hidden;">
                    <div class="jobpost-frame" style="border: 1px solid #e8e7e7;border-radius: 10px; box-shadow: 1px 1px 0  lightgrey;margin-right: 5px;">
                        <div class="card mb-2 jobpost-card">
                            <div class="d-flex justify-content-between " >
                                <a style=" display: inline" [routerLink]="['jobdetails', jobdetails.jobId ]">
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="ms-5 c-details jobheader" *ngIf="jobdetails.isUrgent" style="display: flex;">
                                            <!--div>
                                                <img *ngIf="jobdetails.isUrgent" src="assets/img/immediate.png" alt="Logo" class="immedidate">
                                            </div-->
                                            <div class="col-lg-10 col-md-10 col-sm-10">

                                                <h4 class="jobtitle" style="padding-top: 10px; text-align: center;">
                                                    <img *ngIf="jobdetails.isUrgent" src="assets/img/immediate.png" alt="Logo" class="immedidate">
                                                    <i *ngIf="!jobdetails.isUrgent" class="flaticon-briefcase"></i>
                                                    {{ jobdetails.title }}
                                                </h4>
                                            </div>
                                        </div>
                                        <div class="ms-5 c-details" *ngIf="!jobdetails.isUrgent" >
                                            <h4 class="jobtitle">
                                                <i *ngIf="!jobdetails.isUrgent" class="flaticon-briefcase"></i>
                                                {{ jobdetails.title }}
                                            </h4>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="p-3">
                                <p *ngIf="jobdetails.openPositionsCount" class="job-position-count">
                                    <span class="jobopening-count">{{ jobdetails.openPositionsCount }}
                                    </span> Open Positions
                                </p>
                                <!--div>
                                    <a class='button' (click)="openPopup()">Apply</a>                                   
                                </div-->

                                <div class="mt-3 ">
                                    <p class="truncate-overflow" style="height: 120px;" [innerHTML]="transformText(jobdetails.description || jobdetails.responsibilities || jobdetails.requirements )">
                                    </p>
                                </div>
                                <div class="mt-3">
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div class="mt-3 row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <span class="text1"><i class='bx bx-map' style="color: crimson; font-size: 14px"></i>{{
                                                jobdetails.workLocation }}</span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 payScaleDiv">
                                            <span class="pay-scale" *ngIf="jobdetails.payScale"><i class='bx bx-money' style="color: darkgreen;"></i>
                                                <b style="padding-left: 5px;">{{jobdetails.payScale}}</b></span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 jobLocationDiv">
                                            <span class="text1" *ngIf="jobdetails.duration"><i class='bx bxs-time' style="color: crimson;"></i> {{
                                                jobdetails.duration }} <span *ngIf="isNumeric(jobdetails.duration)"> months</span>
                                            </span>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12 jobPostedDiv" style="align-self: flex-end;   text-align: end">
                                            <span class="text2 postedonDate" *ngIf="jobdetails.updatedTime"> Posted On: {{ jobdetails.updatedTime| date: 'MM/dd/yyyy' ||jobdetails.date }}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

</div>
<!-- Services Details End -->

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 *ngIf="!jobpostTitle" class="modal-title">Submit Your Resume</h4>
                <h4 *ngIf="jobpostTitle" class="modal-title">Apply for {{ jobpostTitle }} Job</h4>
                <a class="modelClose" (click)="closePopup()">X</a>
            </div>
            <div class="modal-body">
                <div class="contact-form" style="padding: 5px !important;">
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row ">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="firstname" id="firstname" type="text" placeholder="First Name" class="form-control ">
                                    <div *ngIf="f.firstname.touched && f.firstname.invalid" class="invalid-feedback">
                                        <div *ngIf="f.firstname.errors.required">First Name is required.</div>
                                        <div *ngIf="f.firstname.errors.minlength">First Name should be 3 character.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="lastname" id="lastname" type="text" placeholder="Last Name" class="form-control">
                                    <div *ngIf="f.lastname.touched && f.lastname.invalid" class="invalid-feedback">
                                        <div *ngIf="f.lastname.errors.required">Last Name is required.</div>
                                        <div *ngIf="f.lastname.errors.minlength">Last Name should be 3 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="email" id="email" type="email" placeholder="Email" class="form-control">
                                    <div *ngIf="f.email.touched && f.email.invalid" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required.</div>
                                        <div *ngIf="f.email.errors.email">Please, enter valid email address.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="phone" id="phone" type="tel" pattern="[0-9]{10}" placeholder="Phone" class="form-control">
                                    <div *ngIf="f.phone.touched && f.phone.invalid" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">phone is required.</div>
                                        <div *ngIf="f.phone.errors.pattern">Should be 10 digit mobile number</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group form-fields">
                                    <textarea name="message" [maxlength]=255 class="form-control" id="message" formControlName="message" [maxlength]=255 cols="30" rows="5" required placeholder="Tell about yourself"></textarea>
                                    <div *ngIf="f.message.touched && f.message.invalid" class="invalid-feedback">
                                        <div *ngIf="f.message.errors.required">Message is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group form-fields">
                                    <!--label for="attachment" style="margin-bottom: 0;">Upload Your Resume</label-->
                                    <input name="attachment" id="attachment" type="file" accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="onUploadFile($event)" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 text-center">
                                <button class="default-btn" id="modelSubmit">Submit</button>
                            </div>
                            <div class="col-lg-12 col-sm-12" [ngClass]="{'successmsg': isSuccessMsg === true}">
                                <strong>{{responseMessage}}</strong>
                            </div>
                        </div>
                    </form>
                </div>

            </div>

        </div>
    </div>
</div>