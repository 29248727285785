<!-- Banner Area -->
<div class="outer-banner-area">
    <div class="banner-area">
        <div class="container">
            <div class="row align-items-center" style="flex-wrap: nowrap;">
                    <div class="banner-content">
                        <!--span>Since 2011</span-->
                        <h1 class="cacheline">RightTalents @ Right Cost @ Right Time</h1>
                        <p style="color: white">RightTalents is a customer centric company for IT Staffing & Services.</p>
                        <div class="banner-btn">
                            <a href="/righttalents#contactarea" class="get-btn">Contact Us</a>
                            <!--a routerLink="/" class="get-btn">Get A Quote</a-->
                        </div>
                    </div>
                    <div  class="credentialsection">
                        <img class="credentialstyle" style="margin-top: -14%" src="assets/img/fast50logo.jpg">
                        <!-- <img class="credentialstyle" height="50" width="100" src="assets/img/client/comptia1.png"> -->
                        <img class="credentialstyle" style="margin-left: 3%" height="150" width="250" src="assets/img/about/new_jersey_mbe.png">
                    </div>
                    <div  class="credentialsection">
                        <img class="credentialstyle"  style="margin-top: -5%" height="120" width="130" src="assets/img/client/comptia.webp">
                    </div>  
                    <div  class="credentialsection">
                        <img class="credentialstyle"  style="margin-top: -5%" height="120" width="130" src="assets/img/NMSDC_CERIFIED_2024.jpg">
                    </div>    
                    <div class="credentialsection">
                        <img class="credentialstyle" height="160" width="120" src="assets/img/mbecertified.png">
                    </div>
                <!--div class="col-lg-6 col-md-12">
                    <div class="banner-img">
                        <img class="overrideimage" src="assets/img/staffing.jpg" alt="Images">

                        <div class="dots">
                            <img src="assets/img/shape/dots.png" alt="Images">
                        </div>
                    </div>
                </div-->
            </div>
        </div>

        <!--div class="banner-shape">
            <div class="shape-icon"><i class="flaticon-graph"></i></div>
        </div-->
    </div>
</div>
<!-- End Banner Area -->

<!-- Special Area -->
<div class="special-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="special-card">
                    <img src="assets/img/righttalents.jpg" alt="Images">
                    <div class="content">
                        <h3>Right Talent</h3>
                        <p>Recruit highly skilled Consultants with the business knowledge and technical expertise in Information Technology (IT) </p>
                        <!--a routerLink="/services-details" class="learn-btn text-center">Learn More</a-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="special-card">
                    <img src="assets/img/dreamstime_xs_159027601.jpg" alt="Images">
                    <div class="content">
                        <h3>Right Cost</h3>
                        <p>Provide scalable and cost effective services for your need to achieve more success with efficiency too.</p>
                        <!--a routerLink="/services-details" class="learn-btn text-center">Learn More</a-->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="special-card">
                    <img src="assets/img/dreamstime_xs_110680552.jpg" alt="Images">
                    <div class="content">
                        <h3>Right Time</h3>
                        <p>Work with you as trusted partner to provide industry leading staffing service at the right moment. </p>
                        <!--a routerLink="/services-details" class="learn-btn text-center">Learn More</a-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Special Area End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <img style="height: 450px; border-radius: 25px" src="assets/img/about/dreamstime_xs_44099241.jpg" alt="Images">

                <!--div class="about-img">
                    <img src="assets/img/about/group-businesspeople-meeting-around-boardroom-table-smiling-44099241.jpg" alt="Images">

                    <div class="about-img-small">
                        <img src="assets/img/about/about-img2.jpg" alt="Images">
                    </div>
                </div-->
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <!--h2>We Are Standing With <b>30+</b> Years Of Experience</h2-->
                    <h3>RightTalents is an IT Services Company and provide IT staffing, consulting and training services.</h3>
                    <p>Pon Palani is the President and CEO of the company. He is an Industry veteran with more than 20 years of experience.</p>
                    <!--div style="padding-left: 20px">
                    <ul class="aboutus">
                        <ol><a href="https://www.linkedin.com/in/ppalani/">Ponnusamy Palanimuthu</a> – President & CEO</ol>
                    </ul>
                    </div-->
                    <div class="row">
                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>4+</h3>
                                    <span>Certifications</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-team"></i>
                                <div class="content">
                                    <h3>10+</h3>
                                    <span>Years of Expertise in IT Staffing</span>
                                </div>
                            </div>
                        </div>

                        <!--div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>5000+</h3>
                                    <span>Business Guide</span>
                                </div>
                            </div>
                        </div-->
                    </div>

                    <div class="about-btn">
                        <a routerLink="/about" class="learn-btn">Learn More</a>
                        <!--a routerLink="/contact" class="get-btn">Get A Quote</a-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Area End -->

<!-- Video Area -->
<div class="video-area video-bg1">
    <div class="container">
        <!--div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="video-btn">
                    <a href="https://www.youtube.com/watch?v=fhlcDnyZo1Q&t=29s" class="video-play-btn popup-btn"><i class="flaticon-play-button"></i></a>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="video-content">
                    <div class="section-title">
                        <span>Intro Video</span>
                        <h2>Explore Us Watch This Full Video and Know Us</h2>
                    </div>
                </div>
            </div>
        </div-->

        <div class="guidelines-area guidelines-bg">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="guidelines-content">
                        <h2>Proper Staffing is a prerequisite for a Business to be successful</h2>
                        <p>Our Vision is to become a trusted partner to all our clients and scale up for any kind of on-demand staffing requirement.</p>

                        <!--div class="signature">
                            <img src="assets/img/signponpalani1.png" alt="Images">
                        </div-->

                        <div class="content" style="float: right">
                            <h3>Pon Palani</h3>
                            <span>President, RightTalents LLC</span>
                        </div>
                    </div>
                    <!--div>
                        <img src="assets/img/recruiter-locating-male-candidate-network-blue-chip-recruitment-agent-highlighting-white-collar-worker-virtual-hr-concept-98462477.jpg"/>
                    </div-->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Video Area End -->

<!-- Services Area -->
<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <span>Our Services</span>
                <h2>Our Best of Class Services will Make You To Reach Your Goal</h2>
            </div>
            <div class="col-lg-12 col-md-12">
                <p>We understand that building great companies requires exceptional talent. We have the expertise to provide individual consultants to work as a part of your team or entire project to deliver. We have the expertise to deliver results that
                    exceed expectations. We carefully screen candidates to ensure that they are an exact fit for technical, personal & cultural aspects. Therefore, as a strategic and trusted partner, we search far and wide to provide the best talent for
                    an organization to be successful. </p>
            </div>
        </div>

        <div class="row pb-20">
            <div class="col-lg-4 col-md-6 d-flex align-items-strech">
                <div class="services-card">
                    <a routerLink="/itstaffing-services" class="services-icon"><i class="flaticon-people"></i></a>
                    <h3><a routerLink="/itstaffing-services">IT Staffing Services</a></h3>
                    <p>Comprehensive array of staffing and consulting services that are custom tailored to meet the unique needs of our clients. It provides high-quality professionals for contract, contract-to-hire, and permanent/fulltime positions.</p>
                    <a routerLink="/itstaffing-services" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6  d-flex align-items-strech">
                <div class="services-card">
                    <a routerLink="/itconsulting-services" class="services-icon"><i class="flaticon-briefcase-1"></i></a>
                    <h3><a routerLink="/itconsulting-services">IT Consulting Services</a></h3>
                    <p>Focused in IT innovations and latest business trends to help our client organizations leverage leading edge technologies for business improvement and maximizing returns on their IT Investment(ROI)</p>
                    <a routerLink="/itconsulting-services" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6  d-flex align-items-strech">
                <div class="services-card">
                    <a routerLink="/ittraining-services" class="services-icon"><i class="flaticon-analytics"></i></a>
                    <h3><a routerLink="/ittraining-services">IT Training Services</a></h3>
                    <p>“Constant Learning” is the “Right Thing” forever and facilitates training for the consultants to advance their skills for their career advancement. Provides training services to upskill for their employees.</p>
                    <a routerLink="/ittraining-services" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <!--div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-chess-pieces"></i></a>
                    <h3><a routerLink="/services-details">Strategy</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-briefcase-1"></i></a>
                    <h3><a routerLink="/services-details">Business Consulting</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-people"></i></a>
                    <h3><a routerLink="/services-details">Human Resources</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>
        </div>

        <div class="service-view-btn text-center">
            <a routerLink="/services-1" class="view-btn">View More</a>
        </div-->
        </div>
    </div>
    <!-- Services Area End -->

    <!-- Choose Area -->
    <div class="choose-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="section-title">
                        <span>Why Choose Us</span>
                        <h2>Trusted Staffing Company To Give You Right Guideline</h2>
                    </div>

                    <div class="tab choose-tab">
                        <ul class="tabs">
                            <li><a href="#">Our Mission</a></li>
                            <li><a href="#">Our Vision</a></li>
                            <li><a href="#">Our Value</a></li>
                        </ul>

                        <div class="tab_content current active pt-45">
                            <div class="tabs_item current">
                                <div class="choose-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-12">
                                            <div class="choose-item-img">
                                                <img src="assets/img/dreamstime_xs_56313770.jpg" alt="Images">
                                            </div>
                                        </div>

                                        <div class="col-lg-8 col-md-12">
                                            <div class="choose-item-content">
                                                <p>Recruit highly qualified professionals to organizations on demand by understanding the organization needs and employees passion. Create Win-Win moment for both Professionals and Organization for a successful
                                                    endevaour
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <!--div class="choose-item-list">
                                    <ul>
                                        <li><i class='bx bx-check'></i> Integrated Strategic Approach To Reach You In Your Goal</li>
                                        <li><i class='bx bx-check'></i> Measurable Performance Doing In Those Years</li>
                                        <li><i class='bx bx-check'></i> More Than 20 Years Of Working Experience In This Sector</li>
                                        <li><i class='bx bx-check'></i> Trusty, Reliable And Responsive Service Provider</li>
                                    </ul>
                                </div-->
                                </div>
                            </div>

                            <div class="tabs_item current">
                                <div class="choose-item">
                                    <!--div class="choose-item-list">
                                    <ul>
                                        <li><i class='bx bx-check'></i> Integrated Strategic Approach To Reach You In Your Goal</li>
                                        <li><i class='bx bx-check'></i> Measurable Performance Doing In Those Years</li>
                                        <li><i class='bx bx-check'></i> More Than 20 Years Of Working Experience In This Sector</li>
                                        <li><i class='bx bx-check'></i> Trusty, Reliable And Responsive Service Provider</li>
                                    </ul>
                                </div-->

                                    <div class="row">
                                        <div class="col-lg-4 col-md-12">
                                            <div class="choose-item-img">
                                                <img src="assets/img/dreamstime_xs_76606780.jpg" alt="Images">
                                            </div>
                                        </div>

                                        <div class="col-lg-8 col-md-12">
                                            <div class="choose-item-content">
                                                <p>Become a trusted partner to all our clients and scale up for any kind on-demand of staffing requirement. Provide best-in class training to empower the individuals make the highly qualified Professionals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tabs_item current">
                                <div class="choose-item">
                                    <!--div class="row">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="choose-item-img">
                                            <img src="assets/img/choose/choose-img1.jpg" alt="Images">
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-12">
                                        <div class="choose-item-content">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sapien orci, varius quis rutrum vel, pellentesque sit amet nisi. Suspendisse placerat posuere massa, ut consectetur est ornare sed. Vivamus enim turpis, blandit.</p>
                                        </div>
                                    </div>
                                </div-->

                                    <div class="choose-item-list">
                                        <ul>
                                            <li><i class='bx bx-check'></i> <b>Right resource</b> -Resource on the skill and attitude needs of organization </li>
                                            <li><i class='bx bx-check'></i> <b>Right cost</b> - Resource at acceptable cost for organization</li>
                                            <li><i class='bx bx-check'></i> <b>Right time</b> - Staffing on the spur of the requirement </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5 col-md-12">
                    <div class="choose-img">
                        <img style="height: 450px" src="assets/img/dreamstime_s_139132274.jpg" alt="Images">
                        <!--div class="images">
                        <img src="assets/img/choose/choose-img3.jpg" alt="Images">
                    </div-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Choose Area End -->

    <!-- Contact Area -->
    <div id="contactarea" class="contact-area pt-300">
        <div class="container">
            <div class="contact-area-bg">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="contact-form">
                            <div class="section-title text-center">
                                <h2>Contact Us</h2>
                            </div>

                            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                                <div class="row ">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group form-fields">
                                            <input formControlName="firstname" id="firstname" type="text" placeholder="First Name" class="form-control ">
                                            <div *ngIf="f.firstname.touched && f.firstname.invalid" class="invalid-feedback">
                                                <div *ngIf="f.firstname.errors.required">First Name is required.</div>
                                                <div *ngIf="f.firstname.errors.minlength">First Name should be 3 character.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group form-fields">
                                            <input formControlName="lastname" id="lastname" type="text" placeholder="Last Name" class="form-control">
                                            <div *ngIf="f.lastname.touched && f.lastname.invalid" class="invalid-feedback">
                                                <div *ngIf="f.lastname.errors.required">Last Name is required.</div>
                                                <div *ngIf="f.lastname.errors.minlength">Last Name should be 3 character.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group form-fields">
                                            <input formControlName="email" id="email" type="email" placeholder="Email" class="form-control">
                                            <div *ngIf="f.email.touched && f.email.invalid" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required.</div>
                                                <div *ngIf="f.email.errors.email">Please, enter valid email address.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group form-fields">
                                            <input formControlName="phone" id="phone" type="tel" pattern="[0-9]{10}" placeholder="Phone" class="form-control">
                                            <div *ngIf="f.phone.touched && f.phone.invalid" class="invalid-feedback">
                                                <div *ngIf="f.phone.errors.required">phone is required.</div>
                                                <div *ngIf="f.phone.errors.pattern">Should be 10 digit mobile number</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group form-fields">
                                            <textarea name="message" class="form-control" id="message" formControlName="message" cols="30" rows="8" [maxlength]=255 required placeholder="Message"></textarea>
                                            <div *ngIf="f.message.touched && f.message.invalid" class="invalid-feedback">
                                                <div *ngIf="f.message.errors.required">Message is required.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group form-fields">
                                            <input type="file" name="attachment" id="attachment" accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="onUploadFile($event)" class="form-control" placeholder="Attachment">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 text-center">
                                        <button class="default-btn">
                                            Submit
                                        </button>
                                    </div>

                                    <div class="col-lg-12 col-sm-12" [ngClass]="{'successmsg': isSuccessMsg === true}">
                                        <strong>{{responseMessage}}</strong>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Contact Area End -->
    <!-- Contact Area End -->