import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { NgRecaptcha3Service } from "ng-recaptcha3";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../../environments/environment";
import { fromEvent, Observable } from "rxjs";
import { pluck } from "rxjs/operators";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-jobdetails",
  templateUrl: "./jobdetails.component.html",
  styleUrls: ["./jobdetails.component.scss"],
})
export class JobdetailsComponent implements OnInit {
  public jobid: string;

  public jobpost: any;

  public joburl: any;

  public host: any;

  public displayStyle = "none";
  public msgAttachement;
  public msgattachmentContentType;
  public user: any;
  public responseMessage: any;
  public isSuccessMsg: boolean;
  private isUnsupporttedFileFormat: boolean;
  public payScale: string;
  public talentTreasureClicked: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private recaptcha3: NgRecaptcha3Service,
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta
  ) {}

  ngOnInit(): void {
    this.host = "https://www.righttalents.net";
    //TODO: check seo works??
    const jobDetailId = this.activatedRoute.snapshot.paramMap.get("jobid");
    if (jobDetailId.includes("-CB")) {
      this.jobid = jobDetailId.split("-CB")[1];
    } else {
      this.jobid = jobDetailId; 
    }
    console.log("job id ", this.jobid);
    
    this.joburl = this.host + this.router.url;
    this.responseMessage = "";
    this.recaptcha3.init(environment.siteKey);
    this.titleService.setTitle(`Apply for Job Posts`);
    this.metaService.updateTag({ name: "og:title", content: "Apply for Job Posts" });
    this.metaService.updateTag({ name: "og:description", content: "Job post descriptions " });
    this.metaService.updateTag({name: "og:image", content: 'https://righttalents.net/assets/img/rtlogotransparent1.png'});
    this.metaService.updateTag({
      name: "og:url",
      content: `https://righttalents.net/openpositions/jobdetails/${this.jobid}`,
    });
    this.metaService.updateTag({ name: "title", content: "Apply for Job Posts" });
    this.metaService.updateTag({ name: "description", content: "Job post descriptions " });
    this.metaService.updateTag({name: "image", content: 'https://righttalents.net/assets/img/rtlogotransparent1.png'})
    this.getJSON();
  }

  ngAfterViewChecked(): any {
    const element = <HTMLElement>document.getElementsByClassName("grecaptcha-badge")[0];
    if (element) element.style.zIndex = "-1";
  }
  
  contactForm = new FormGroup({
    firstname: new FormControl("", [Validators.required, Validators.minLength(3)]),
    lastname: new FormControl("", [Validators.required, Validators.minLength(3)]),
    phone: new FormControl("", [Validators.required, Validators.pattern("[0-9]{10}")]),
    email: new FormControl("", [Validators.required, Validators.email]),
    isUpskill: new FormControl("", []),
    message: new FormControl("", [Validators.required]),
  });

  get f() {
    return this.contactForm.controls;
  }
  setTalentTreasureClicked() {
    this.talentTreasureClicked = true;
    setTimeout(() => {
      window.open("https://talentstreasure.com?rtcampaign=true", "_blank", "noopener,noreferrer");
    }, 100);
  }

  isNumeric(value: string) {
    return !isNaN(Number(value));
  }

  onSubmit() {
    if (this.contactForm.invalid) {
      // console.log("rawvalue", this.contactForm.getRawValue());
      this.responseMessage = "Please fill in the required information to connect with you back..";
      this.resetResponseMessage();
      return;
    } else if (this.isUnsupporttedFileFormat) {
      this.responseMessage = "Unsupported file format please upload the pdf / doc / docx..";
      this.resetResponseMessage();
      return;
    }
    
   if (this.talentTreasureClicked) {
    this.contactForm.patchValue({ isUpskill: true });
  }else{
     this.contactForm.patchValue({ isUpskill: false });
  }
    this.user = this.contactForm.value;
    let objectBody = {
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      mailId: this.user.email,
      phoneNo: this.user.phone,
      message: this.user.message + "<jobtitle:" + this.jobpost?.title + ">",
      isUpskill: this.user.isUpskill,
      msgattachment: this.msgAttachement ? this.msgAttachement : "",
      msgattachmentContentType: this.msgattachmentContentType ? this.msgattachmentContentType : "",
    };
    this.recaptcha3.getToken().then(
      (token) => {
        const headers = {
          recaptchaToken: token,
        };
        this.http.post<any>("https://righttalents.net/api/contactuses", objectBody, { headers }).subscribe((data) => {
          if (data.id !== null && data.id !== undefined && data.id !== "") {
            this.responseMessage = "Thanks! We will get back to you soon!";
            this.isSuccessMsg = true;
          } else {
            this.responseMessage = "Internal Error! Please try again...";
            this.isSuccessMsg = false;
          }
          this.resetResponseMessage();
        });
      },
      (error) => {
        this.responseMessage = "Internal Error! Please try again...";
        this.resetResponseMessage();
        console.error(error);
        return;
      }
    );
  }
  resetResponseMessage() {
    setTimeout(() => {
      this.responseMessage = "";
    }, 8000);
  }
  onUploadFile(event) {
    if (event.target.files.length > 0) {
      this.isUnsupporttedFileFormat = false;
      document.getElementById("attachment").style.display = "block";
      const fileReader = new FileReader();
      let fileToUpload = event.target.files.item(0);
      this.msgattachmentContentType = fileToUpload.type;
      const lastIndex = fileToUpload.name.lastIndexOf(".");
      const fileType = fileToUpload.name.slice(lastIndex + 1);
      if (fileType !== "pdf" && fileType !== "doc" && fileType !== "docx") {
        this.isUnsupporttedFileFormat = true;
        this.isSuccessMsg = false;
      } else {
        this.fileToBase64(fileReader, fileToUpload).subscribe((base64file) => {
          this.msgAttachement = base64file.split("base64,")[1].trim();
        });
      }
    }
  }

  fileToBase64(fileReader: FileReader, fileToRead: File): Observable<string> {
    fileReader.readAsDataURL(fileToRead);
    return fromEvent(fileReader, "load").pipe(pluck("currentTarget", "result"));
  }
  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }

  public getJSON() {
    const url = `https://righttalents.net/api/active/job-posts/${this.jobid}`;
    this.http
      .get<any[]>(url, {
        headers: {
          Origin: "https://righttalents.net",
        },
      })
      .subscribe((data: any) => {
        this.jobpost = data;
        console.log("job posts retrieved it ---", this.jobpost);
        if (!this.jobpost) {
          this.router.navigate(["openpositions"]);
        } else {
          let openpositionsStr = this.jobpost.openPositionsCount ? `${this.jobpost.openPositionsCount} Open Positions` : "";
          this.titleService.setTitle(`Apply for ${this.jobpost?.title} job`);
          this.metaService.updateTag({ name: "og:title", content: `Apply for ${this.jobpost?.title} job | ${this.jobpost.isUrgent ? " Urgent Requirement " : ""} | ${openpositionsStr}` });
          this.metaService.updateTag({ name: "og:description", content: ` Work Location: ${this.jobpost.workLocation} ${this.jobpost.isUrgent ? "| Urgent Requirement " : ""} | ${openpositionsStr} | Job Title: ${this.jobpost?.title} ` });
          this.metaService.updateTag({
            name: "og:url",
            content: `https://righttalents.net/openpositions/jobdetails/${this.jobid}`,
          });
          this.metaService.updateTag({
            name: "url",
            content: `https://righttalents.net/openpositions/jobdetails/${this.jobid}`,
          });
          this.metaService.updateTag({ name: "title", content: `Apply for ${this.jobpost?.title} job | ${this.jobpost.isUrgent ? " Urgent Requirement " : ""} | ${openpositionsStr}` });
          this.metaService.updateTag({ name: "description", content: ` Work Location: ${this.jobpost.workLocation} ${this.jobpost.isUrgent ? "| Urgent Requirement " : ""} | ${openpositionsStr} | Job Title: ${this.jobpost?.title} ` });
        }
        // console.log('jobposts::', this.jobpost);
      });
  }
}
