<!-- Top Header -->
<header class="top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-9 moveleft">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-location-plus'></i>
                                </div>
                                <a href="https://goo.gl/maps/JcsuM2wvETs9ZVW69" target="_blank">639 Passaic Ave STE C, Nutley, NJ 07110</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-envelope'></i>
                                </div>
                                <a href="mailto:info@righttalents.net">info@righttalents.net</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-3 moveright">
                <div class="header-right">
                    <div class="top-social-link">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://twitter.com/RighttalentsR" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <!--li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li-->
                            <li><a href="https://www.linkedin.com/company/righttalents-llc/" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <!--div class="language">
                        <ul>
                            <li>
                                <a href="javascript:void(0)"><i class='bx bx-world language-icon'></i> ENG <i class='bx bx-chevron-down'></i></a>
                                <ul>
                                    <li><a href="#">ENG</a></li>
                                    <li><a href="#">DE</a></li>
                                    <li><a href="#">UAE</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div-->
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/rtlogobanner1.jpg" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img style="height: 75px;" src="assets/img/rtlogobanner1.jpg" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Home</a>

                            <!--ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Three</a></li>
                            </ul-->
                        </li>
                        <li class="nav-item"><a href="/about" class="nav-link">About Us</a></li>

                        <!--li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/apply-now" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Apply Now</a></li>

                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link">Projects <i class='bx bx-chevron-down'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/projects" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Projects</a></li>

                                        <li class="nav-item"><a routerLink="/projects-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Projects Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/case-study" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Study</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>

                                <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                            </ul>
                        </li-->

                        <li class="nav-item">
                            <!--TO DO: Change for absolute url-->
                            <a href="/righttalents#services" class="nav-link">Services <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/itstaffing-services" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">IT Staffing Services</a></li>

                                <li class="nav-item"><a routerLink="/itconsulting-services" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">IT Consulting Services</a></li>

                                <li class="nav-item"><a routerLink="/ittraining-services" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">IT Training Services</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/employers" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Employers</a></li>
                        <li class="nav-item"><a routerLink="/jobseekers" routerLinkActive="active" class="nav-link">Job Seekers<i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/jobseekers" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Job Seekers</a></li>
                                <li class="nav-item"><a routerLink="/openpositions" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Open Positions</a></li>
                                <li class="nav-item"><a routerLink="/clients" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Clients</a></li>
                                <li class="nav-item"><a routerLink="/prepfeed" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Prepration Feed</a></li>
                            </ul>
                        </li>
                        <!--li class="nav-item"><a routerLink="/training" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Training</a></li-->
                        <!--li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Style One</a></li>

                                <li class="nav-item"><a routerLink="/blog-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Style Two</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li-->


                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="others-options d-flex align-items-center">
                        <a class="option-item searchIcon" routerLink="/openpositions">
                            <i class='bx bx-search' style="color: white"> <span style="padding-left: 15%; color: white;">Jobs</span></i>
                        </a>
                        <div class="option-item ">
                            <div class="menu-icon d-in-line ">
                                <span class="burger-menu menu-icon-two "><i class='flaticon-menu'></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Sidebar Modal -->
<div class="sidebar-modal ">
    <div class="sidebar-modal-inner ">
        <div class="sidebar-header ">
            <div class="sidebar-logo ">
                <img src="assets/img/rtnavlogo.jpeg " alt="Image ">
            </div>
            <span class="close-btn sidebar-modal-close-btn "><i class="bx bx-x "></i></span>
        </div>

        <div class="sidebar-about ">
            <div class="title ">
                <p>RightTalents believes in diversity for inclusive, sustainable growth and development.</p>
            </div>
        </div>

        <div class="contact-us ">
            <h2>Contact Us</h2>

            <ul>
                <li>
                    <i class='bx bx-current-location'></i> Address: 639 Passaic Ave STE C, Nutley, NJ 07110
                </li>
                <li>
                    <i class='bx bx-mail-send'></i>
                    <a href="mailto:info@righttalents.net ">info@righttalents.net</a>
                </li>
                <li>
                    <i class='bx bx-phone-call'></i> Ph :<a style="display:inline-block " href="tel:+1-973-320-8610 "> +1-973-320-8610</a><br /> Fax:
                    <a style="display: inline-block " href="tel:+1-862-772-0446 "> +1-862-772-0446</a>
                </li>
            </ul>
        </div>

        <!--div class="sidebar-instagram-feed ">
            <h2>Instagram</h2>
            <ul>
                <li>
                    <a target="_blank " href="# "><img src="assets/img/instagram/instagram1.jpg " alt="image "></a>
                </li>
                <li>
                    <a target="_blank " href="# "><img src="assets/img/instagram/instagram2.jpg " alt="image "></a>
                </li>
                <li>
                    <a target="_blank " href="# "><img src="assets/img/instagram/instagram3.jpg " alt="image "></a>
                </li>
                <li>
                    <a target="_blank " href="# "><img src="assets/img/instagram/instagram4.jpg " alt="image "></a>
                </li>
                <li>
                    <a target="_blank " href="# "><img src="assets/img/instagram/instagram5.jpg " alt="image "></a>
                </li>
                <li>
                    <a target="_blank " href="# "><img src="assets/img/instagram/instagram6.jpg " alt="image "></a>
                </li>
            </ul>
        </div-->

        <div class="sidebar-follow-us ">
            <h2>Follow Us</h2>

            <ul class="social-wrap ">
                <li><a href="https://twitter.com/RighttalentsR " target="_blank "><i class="bx bxl-twitter "></i></a></li>
                <li><a href="# " target="_blank "><i class="bx bxl-instagram "></i></a></li>
                <li><a href="# " target="_blank "><i class="bx bxl-facebook "></i></a></li>
                <li><a href="# " target="_blank "><i class="bx bxl-youtube "></i></a></li>
            </ul>
        </div>
    </div>
</div>
<!-- End Sidebar Modal -->