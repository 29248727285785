import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgRecaptcha3Service } from 'ng-recaptcha3';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { Title, Meta } from "@angular/platform-browser";
import { fromEvent, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import _ from 'lodash';

@Component({
  selector: 'prepfeed',
  templateUrl: './prepfeed.component.html',
  styleUrls: ['./prepfeed.component.scss']
})
export class PrepFeedComponent implements OnInit {

  jobposts: any[];
  public msgAttachement;
  public msgattachmentContentType;
  public user: any;
  public responseMessage: any;
  public isSuccessMsg: boolean = false;
  public displayStyle = "none";
  private isUnsupporttedFileFormat: boolean;
  public jobpostTitle = "General";

  constructor(private recaptcha3: NgRecaptcha3Service, private http: HttpClient, private titleService: Title, private metaService: Meta) { }


  ngOnInit(): void {
    this.responseMessage = "";
    this.recaptcha3.init(environment.siteKey);
    this.getJSON();
  }

  ngAfterViewChecked(): any {
    const element = <HTMLElement>document.getElementsByClassName('grecaptcha-badge')[0];
    element.style.zIndex = '-1';
  }
  transformText(input) {
    let replacedText = "";
    while (replacedText != input) {
      replacedText = input.replace("\n", "");
      input = replacedText;
    }
    return replacedText;
  }

  stringToSlug(str): string {
    return _.kebabCase(str.replace(/[\W_]+/g, '-'));
  }

  public getJSON() {
    const url = 'https://righttalents.net/api/active/job-posts?page=0&size=200&sort=updatedTime,desc';
    this.http.get<any[]>(url, {
      headers: {
        'Accept': 'application/json',
      }
    }).subscribe((res) => {
      //  res.on('data', (data) => {
      // console.log(res);
      this.jobposts = res;
      this.titleService.setTitle(`Search for US Open Job Positions`);
      this.metaService.updateTag({ name: 'og:title', content: 'Search for US Open Job Positions' });
      this.metaService.updateTag({ name: 'og:description', content: `Explore IT Job Opportunities available at United States(US). Search for in-office and remote job positions. ` });
      this.metaService.updateTag({ name: 'og:url', content: 'https://righttalents.net/prepfeed' });
      this.metaService.updateTag({ name: 'og:image', content: 'https://righttalents.net/assets/img/rtlogotransparent1.png' })
      this.metaService.updateTag({ name: 'title', content: 'Search for US Open Job Positions' });
      this.metaService.updateTag({ name: 'description', content: `Explore IT Job Opportunities available at RightTalents. Search for in-office and remote job positions. ` });
      this.metaService.updateTag({ name: 'image', content: 'https://righttalents.net/assets/img/rtlogotransparent1.png' });
      // });
    });
    // return fetch(url,
    //  {method: 'GET',
    //   headers: {
    //     'Origin': 'https://righttalents.net',
    //     }})
    //  .then((res: any) => res.json())
    //  .then((data: any)=> {
    //    this.jobposts = data;})
    //   .catch((error: any) => {console.log(error)});
  }
  contactForm = new FormGroup({
    firstname: new FormControl('', [Validators.required, Validators.minLength(3)]),
    lastname: new FormControl('', [Validators.required, Validators.minLength(3)]),
    phone: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', Validators.required),
  });

  get f() {
    return this.contactForm.controls;
  }

  onSubmit() {
    if (this.contactForm.invalid) {
      this.responseMessage = "Please fill in the required information to connect with you back..";
      this.resetResponseMessage();
      return;
    } else if (this.isUnsupporttedFileFormat) {
      this.isSuccessMsg = false;
      this.responseMessage = "Unsupported file format please upload the pdf / doc / docx..";
      this.resetResponseMessage();
      return;
    }
    this.user = this.contactForm.value;
    let jobPost = this.jobpostTitle || "General Applicant";
    let msg = this.user.message + "<jobtitle:" +  jobPost + ">";
    let objectBody = {
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      mailId: this.user.email,
      phoneNo: this.user.phone,
      message: msg,
      msgattachment: this.msgAttachement ? this.msgAttachement : '',
      msgattachmentContentType: this.msgattachmentContentType ? this.msgattachmentContentType : ''
    }
    this.recaptcha3.getToken().then(
      (token) => {
        const headers = {
          'recaptchaToken': token
        };
        this.http.post<any>('https://righttalents.net/api/contactuses', objectBody, { headers }).subscribe(data => {
          if (data.id !== null && data.id !== undefined && data.id !== '') {
            this.responseMessage = "Thanks! We will get back to you soon!";
            this.isSuccessMsg = true;
          } else {
            this.responseMessage = "Internal Error! Please try again...";
            this.isSuccessMsg = false;
          }
          this.resetResponseMessage();
        });
      },
      (error) => {
        this.responseMessage = "Internal Error! Please try again...";
        this.resetResponseMessage();
        console.error(error);
        return;
      });
  }
  resetResponseMessage() {
    setTimeout(() => {
      this.responseMessage = "";
    }, 8000);
  }
  onUploadFile(event) {
    if (event.target.files.length > 0) {
      this.isUnsupporttedFileFormat = false;
      document.getElementById('attachment').style.display = 'block';
      const fileReader = new FileReader();
      let fileToUpload = event.target.files.item(0);
      this.msgattachmentContentType = fileToUpload.type;
      const lastIndex = fileToUpload.name.lastIndexOf('.');
      const fileType = fileToUpload.name.slice(lastIndex + 1);
      if (fileType !== "pdf" && fileType !== "doc" && fileType !== "docx") {
        this.isUnsupporttedFileFormat = true;
        this.isSuccessMsg = false;
      } else {
        this.fileToBase64(fileReader, fileToUpload)
          .subscribe(base64file => {
            this.msgAttachement = base64file.split('base64,')[1].trim();
          });
      }
    }
  }

  fileToBase64(fileReader: FileReader, fileToRead: File): Observable<string> {
    fileReader.readAsDataURL(fileToRead);
    return fromEvent(fileReader, 'load').pipe(pluck('currentTarget', 'result'));
  }
  openPopup(appliedJobPost: string) {
    this.displayStyle = "block";
    this.jobpostTitle = appliedJobPost;
  }
  closePopup() {
    this.displayStyle = "none";
  }
}
